<script lang="ts" setup>
import { ref, computed } from "vue";

import { formatPrice } from "@/helpers/price";

import type { Booking, GON } from "@/types";
import BaseForm from "@/components/BaseForm.vue";

declare global {
  interface Window {
    gon: GON;
  }
}

const { gon } = window;
const booking = ref<Booking>(gon.booking);
const cardFees = ref(gon.booking?.credit_card_fees);
const creditCardFeesDisabled = ref(Boolean(gon.bookingServicesNotPaidByClient));
const creditCardFees = ref(Boolean(cardFees.value) || false);
const totalOnlineClientPrice = ref(gon.total_online_client_price);
const totalOnlineClientPriceWithRefund = ref(
  gon.total_online_client_price_with_refund,
);
const totalCommissionWithVat = ref(gon.total_commission_with_vat);
const totalCommissionWithRefund = ref(gon.total_commission_with_refund);
const tooltipVisible = ref(false);

const creditCardFeesAttributes = computed(() => ({
  disabled: !creditCardFeesDisabled.value,
  class: !creditCardFeesDisabled.value ? "bg-gray-200 opacity-60" : "",
  title: !creditCardFeesDisabled.value
    ? "Credit card fees can't be modified once a service has been paid by the client."
    : "",
}));

const changeCreditCardFees = () => {
  cardFees.value = 0;
};
</script>

<template>
  <div class="md:w-[403px]">
    <BaseForm
      :id="`edit_booking_${booking.id}`"
      novalidate
      :action="`/bookings/${booking.id}`"
      accept-charset="UTF-8"
      method="post"
      patch
    >
      <div class="grid grid-cols-2 md:gap-4 w-[300px]">
        <div class="flex-col">
          <div class="text-md font-medium">Total client price</div>
          <div>
            <span class="text-sm text-gray-600">(Incl. VAT)</span>
          </div>
          <div
            class="font-medium text-gray-800"
            v-if="totalOnlineClientPriceWithRefund == totalOnlineClientPrice"
          >
            {{
              formatPrice({
                price: totalOnlineClientPrice,
                currency: "EUR",
              })
            }}
          </div>
          <div
            class="flex flex-col"
            v-if="totalOnlineClientPriceWithRefund != totalOnlineClientPrice"
          >
            <div class="font-medium text-gray-800 line-through">
              {{
                formatPrice({
                  price: totalOnlineClientPrice,
                  currency: "EUR",
                })
              }}
            </div>
            <div class="font-medium text-gray-800">
              {{
                formatPrice({
                  price: totalOnlineClientPriceWithRefund,
                  currency: "EUR",
                })
              }}
            </div>
          </div>
        </div>
        <div class="flex-col">
          <div class="text-md font-medium">Total commission</div>
          <div>
            <span class="text-sm text-gray-600">(Incl. VAT)</span>
          </div>
          <div
            class="font-medium text-gray-800"
            v-if="totalCommissionWithRefund == totalCommissionWithVat"
          >
            {{
              formatPrice({
                price: totalCommissionWithVat,
                currency: "EUR",
              })
            }}
          </div>
          <div
            class="flex flex-col"
            v-if="totalCommissionWithRefund != totalCommissionWithVat"
          >
            <div class="font-medium text-gray-800 line-through">
              {{
                formatPrice({
                  price: totalCommissionWithVat,
                  currency: "EUR",
                })
              }}
            </div>
            <div class="font-medium text-gray-800">
              {{
                formatPrice({
                  price: totalCommissionWithRefund,
                  currency: "EUR",
                })
              }}
            </div>
          </div>
        </div>
      </div>
      <div
        @mouseenter="tooltipVisible = true"
        @mouseleave="tooltipVisible = false"
        :class="[
          'mb-4 mt-3 flex relative items-start items-center justify-between boolean optional booking_service_date_range h-[50px]',
          { 'opacity-60': !creditCardFeesDisabled },
        ]"
      >
        <div
          v-if="tooltipVisible && creditCardFeesAttributes.title"
          class="absolute bg-gray-800 text-white text-xs rounded px-2 py-1 shadow-lg z-10"
        >
          {{ creditCardFeesAttributes.title }}
        </div>
        <div class="flex">
          <div class="flex items-center h-5">
            <input
              v-model="creditCardFees"
              class="focus:ring-2 focus:ring-primary-500 ring-offset-2 h-4 w-4 text-primary-600 border-gray-300 rounded"
              type="checkbox"
              :checked="creditCardFees"
              id="card_fees"
              @change="changeCreditCardFees"
              :class="[creditCardFeesAttributes.class]"
              :disabled="creditCardFeesAttributes.disabled"
            />
          </div>
          <div class="flex ml-2 text-sm items-center">
            <label class="block optional text-md font-medium" for="card_fees">
              Pay by credit card (fees)
            </label>
          </div>
        </div>
        <div class="flex items-center justify-between">
          <div v-show="creditCardFees" class="relative mr-4">
            <input
              v-model.number="cardFees"
              name="booking[credit_card_fees]"
              id="booking_credit_card_fees"
              :class="[
                'shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 focus:outline-none focus:ring-0 focus:border-blue-500 text-gray-400 leading-6 transition-colors duration-200 ease-in-out numeric',
                creditCardFeesAttributes.class,
              ]"
              :disabled="creditCardFeesAttributes.disabled"
            />
            <span
              class="absolute top-1/2 right-2 transform -translate-y-1/2 text-gray-400"
            >
              %
            </span>
          </div>
          <button
            type="submit"
            :class="[
              'font-bold py-2 px-4 rounded',
              creditCardFeesDisabled
                ? 'bg-primary-500 hover:bg-primary-700 text-white'
                : 'bg-gray-300 opacity-60',
            ]"
            :disabled="creditCardFeesAttributes.disabled"
          >
            Save
          </button>
        </div>
      </div>
    </BaseForm>
  </div>
</template>
